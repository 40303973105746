import React, { useEffect, useMemo, useState } from "react";
import styles from "./Home.module.css";
import './Home.css';
import SwipeableViews from "react-swipeable-views";
import {
  Box,
  Button,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  debounce,
} from "@mui/material";
import CustomTable from "../CustomTable/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toggleLoader } from "../../Store/loader/loaderReducer";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { postInfoAction } from "../../Store/PostInfo/postInfo_action";
import { OrgNavigation } from "../../Utils/handleOrgNavigation";
import moment, { now } from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddIcon from "@mui/icons-material/Add";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { areValuesEmpty, getPathFromLocalStroage } from "../../utils";
import Autocomplete from '@mui/material/Autocomplete'; import { Link, useLocation, useNavigate } from "react-router-dom";
import InputSchedule from "../Modal/InputScheduleModal/InputSchedule";


const initalState = { title: "", keyword: [], date: "", content: "", id: "" };
const uploadOptions = ["File Upload"];
const disabledinputs = [2, 4];


const tableHeader = ["Drafts", "Scheduled", "Published", "Pending Review", "Failed", "Trash"];
const trashAlert = ["You can always restore this post to Drafts later.","Are you sure you want to move this post to trash? Its views, likes and comments will be deleted.","Are you sure you want to move this post to trash? Its views, likes and comments will be deleted.","You can always restore this post to Drafts later.","You can always restore this post to Drafts later."]

const Home = () => {

  const [id, setId] = useState([]);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  const [error, setError] = useState(null);
  const [tabvalue, setTabValue] = useState(null);
  const [confirmation, setConfirmation] = useState(false);

  const [postType, setPostType] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [schedule, setSchedule] = useState(initalState);

  const [inputValue, setInputValue] = useState('');
  const [postCount, setPostCount] = useState();
  const [options, setOptions] = useState([]);

  
  const [minTime, setMinTime] = useState(new Date());

  const dispatch = useDispatch();
  const navPath = OrgNavigation();
  const navigate = useNavigate();

  const org_info = useSelector((state) => state?.org_info?.value);
  const timeZone = JSON.parse(org_info.setting).timezone;
  const org_id = org_info?.default_organizations?.Id;

  const currentDate = moment.tz(timeZone).format('YYYY-MM-DD hh:mm a');
  const selectedDate = moment(schedule.date).format('YYYY-MM-DD hh:mm a');
  const currentDates = moment(currentDate, 'YYYY-MM-DD hh:mm a')._d
  const selectedDates = moment(selectedDate, 'YYYY-MM-DD hh:mm a')._d
  const enableAccess = currentDates <= selectedDates

  const headerIdx = {
    Drafts: 0,
    Scheduled: 1,
    Published: 2,
    Failed: 3,
    "Pending Review": 4,
    Trash: 5,
  };


  useEffect(() => {
    const pathLocal = getPathFromLocalStroage();
    setTabValue(pathLocal.value);
  }, []);

  useEffect(() => {
    const calculatedMinTime = getMinTimeForTimezone(timeZone);
    setMinTime(calculatedMinTime);
  }, []);

  useEffect(() => {
    if (tabvalue !== null) {
      _countApi()
    }
  }, [tabvalue]);


  // useEffect(() => {
  //   if(postdatas){
  //     const hasStatusOne =  postdatas?.some((value) => value.Post_Processing_Status === 1 );
  //      if(hasStatusOne){
  //       setTimeout(, 5000);
  //       _countApi()
  //      }
  //   }
  // }, [postdatas]);


  const _countApi = () => {
    const data = {
      org_id: org_info?.default_organizations?.Id,
    }
    axios.get(process.env.REACT_APP_POSTCOUNT, {
      params: data,
      withCredentials: process.env.REACT_APP_CREDENTIALS,
    })
      .then((res) => {
        setPostCount(res.data.response[0])
      })
  }


  const handelPostCount = (key) => {
    switch (key) {
      case 'Drafts':
        return postCount?.countOfDraft;
        break;
      case 'Scheduled':
        return postCount?.countOfSchedule;
        break;
      case 'Published':
        return postCount?.countOfPublish;
        break;
      case 'Failed':
        return postCount?.countOfFailed;
        break;
      case 'Pending Review':
        return postCount?.countOfPendingReview;
        break;
      case 'Trash':
        return postCount?.countOfTrash;
        break;
      default:
        return ''
    }
  }

  const handleInputChange = (event, newInputValue) => {
    setOptions([newInputValue])
    setInputValue(newInputValue);
  };

  const handleSelectChange = (event, newSelectedOptions) => {
    setSchedule({ ...schedule, keyword: newSelectedOptions });
    // setSelectedOptions(newSelectedOptions);
  };

  const handleChange = (event, name) => {
    setTabValue(name);
  };
  const handleChangeIndex = (name) => {
    setTabValue(name);
  };

  const handelValidate = (values) => {
    let errors = {};

    if (!values.title) {
      errors.title = "Title is required";
    }

    // if (!values.keyword[0]) {
    //   errors.keyword = "Keyword is required";
    // }
    if (edit) {
      if (!values.content) {
        errors.content = "Content is required";
      }
    }
    if (edit && tabvalue === 1) {
      if (!values.date) {
        errors.date = "Date is required";
      }
    }
    return errors;
  };

  const handlePost = (type) => {
    let validate = handelValidate(schedule);

    if (Object.keys(validate)?.length) {
      setError(validate);
      return;
    }

    if (!edit) {
      delete schedule.content;
      delete schedule.id;
    }

    const isEmpty = areValuesEmpty(schedule);
    const tapValueStatus = type===1?0:type===2?schedule.date?1:2:type===3&&4
    

    if (isEmpty) {
      let params = {
        org_id: org_info?.default_organizations?.Id,
        title: schedule?.title,
        keywords: schedule?.keyword.join(", "),
        scheduled_date: schedule?.date
          ? moment(schedule?.date, ["DD-MMM-yyy h:mm"]).format(
            "DD-MMM-yyy HH:mm"
          )
          : null,
        type: type,
      };
      if (!schedule?.date) delete params?.scheduled_date;
      const formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });

      dispatch(toggleLoader(true));
      axios
        .post(process.env.REACT_APP_BOTCONTENT, formData, {
          withCredentials: process.env.REACT_APP_CREDENTIALS,
        })
        .then((res) => {
          dispatch(toggleLoader(false));
          setSchedule(initalState);
          setSchedule({ ...schedule, keyword: [] });
          setOpen(false);
          setEdit(false);
          toast.success("Success");
          setError(null);
          setTabValue(tapValueStatus)
          tapValueStatus===tabvalue && usersDetails(tapValueStatus)
         
        })
        .catch((err) => {
          dispatch(toggleLoader(false));
          if (
            err?.response?.data?.error?.message === "invalid_api_credentials"
          ) {
            toast.error(err.response.data.error.details);
          } else if (
            err?.response?.data?.error?.message === "file_data_invalid" &&
            schedule?.keyword?.length === 0
          ) {
            toast.warn(
              "After entering text, please click the enter button in the keyword input field."
            );
          } else {
            toast.error(err.message);
          }
        });
    }
  };

  const usersDetails = (tapValueStatus) => {
    if (tabvalue !== null) {

      let data = {
        limit: 100,
        cursor: 1,
        org_id: org_info?.default_organizations?.Id,
        status:tapValueStatus?tapValueStatus:tabvalue,
      };
      dispatch(toggleLoader(true));
      axios
        .get(process.env.REACT_APP_POST, {
          params: data,
          withCredentials: process.env.REACT_APP_CREDENTIALS,
        })
        .then((res) => {
          dispatch(postInfoAction(res?.data));
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false));
        });
    }
  };

  const postOptions = (value, type) => {
    setConfirmation(false);
    let data = {
      ids: value ? value : id,
      limit: 100,
      cursor: 1,
      org_id: org_info?.default_organizations?.Id,
    };
    dispatch(toggleLoader(true));
    const API =
      type === "Trash"
        ? process.env.REACT_APP_POST
        : type === "Delete"
          ? process.env.REACT_APP_TRASH :
          type === "Revert" ?
            process.env.REACT_APP_REVERTDRAFT :
            process.env.REACT_APP_RESTORE
    if (type === "Restore") {
      axios
        .post(API, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
        .then((res) => {
          dispatch(toggleLoader(false));
          usersDetails();
          setTabValue(0);
          _countApi()
          setOpen(false);
          setEdit(false);
          toast.success(
            postType === "Restore" && "Post restored and moved to drafts."
          );
        })

        .catch((err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false));
        });
    }
    else if (type === "Revert") {
      axios
        .post(API, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
        .then((res) => {
          dispatch(toggleLoader(false));
          usersDetails();
          setTabValue(0);
          _countApi()
          setOpen(false);
          setEdit(false);
          toast.success(
            postType === "Restore" && "Post restored and moved to drafts."
          );
        })

        .catch((err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false));
        });
    }

    else {
      axios
        .delete(API, {
          data: data,
          withCredentials: process.env.REACT_APP_CREDENTIALS,
        })
        .then((res) => {
          dispatch(toggleLoader(false));
          usersDetails();
          setOpen(false);
          setEdit(false);
          _countApi();
          setTabValue("Trash" ? 5 : tabvalue);
          toast.success(
            postType === "Trash" ? "Post moved to trash." : "Post deleted."
          );
        })

        .catch((err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false));
        });
    }
  };

  const postSearch = (value, status) => {
    let data = {
      search: value,
      status: status,
      limit: 100,
      cursor: 1,
      org_id: org_info?.default_organizations?.Id,
    };

    if (!value?.length) delete data.search;

    axios
      .get(process.env.REACT_APP_POST, {
        params: data,
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        dispatch(postInfoAction(res?.data));
        dispatch(toggleLoader(false));
        setOpen(false);
        setEdit(false);
      })

      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
  };

  useEffect(() => {
    setSearchValue('');
    usersDetails();

    switch (tabvalue) {
      case 0:
        navPath("/post/drafts");
        localStorage.setItem(
          "path",
          JSON.stringify({ path: "/post/drafts", value: 0 })
        );
        break;
      case 1:
        navPath("/post/scheduled");
        localStorage.setItem(
          "path",
          JSON.stringify({ path: "/post/scheduled", value: 1 })
        );
        break;
      case 2:
        navPath("/post/published");
        localStorage.setItem(
          "path",
          JSON.stringify({ path: "/post/published", value: 2 })
        );
        break;
      case 3:
        navPath("/post/failed");
        localStorage.setItem(
          "path",
          JSON.stringify({ path: "/post/failed", value: 3 })
        );
        break;
      case 4:
        navPath("/post/pending-review");
        localStorage.setItem(
          "path",
          JSON.stringify({ path: "/post/pending-review", value: 4 })
        );
        break;
      case 5:
        navPath("/post/trash");
        localStorage.setItem(
          "path",
          JSON.stringify({ path: "/post/trash", value: 5 })
        );
        break;
      default:
        break;
    }
  }, [tabvalue]);

  const selectedIds = (val, type) => {
    const value = val.map((item) => item.Id);
    setId(value);
    setPostType(type);
    if (type === "Restore") {
      postOptions(value, type);
    }
    else if (type === "Revert") {
      postOptions(value, type);
    }
    else setConfirmation(true);
  };

  const handleDraft = (val) => {


    let validate = handelValidate(schedule);

    if (Object.keys(validate)?.length) {
      setError(validate);
      return;
    }
    const isEmpty = areValuesEmpty(schedule);
    if (isEmpty) {
      let data = {
        title: val ? val.Title : schedule.title,
        keywords: val ? val.Keywords : schedule.keyword,
        scheduled_date: val
          ? val.Scheduled_Time
          : moment(schedule?.date).format("DD-MMM-YYYY hh:mm"),
        content: val ? val.Content : schedule.content,
        org_id: val ? val.Org_Id : org_info?.default_organizations?.Id,
      };

      dispatch(toggleLoader(true));
      axios
        .post(process.env.REACT_APP_POST, data, {
          withCredentials: process.env.REACT_APP_CREDENTIALS,
        })
        .then((res) => {
          dispatch(toggleLoader(false));
          setOpen(false);
          setEdit(false);
          usersDetails();
          setError(null);
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false));
        });
    }
  };
  const handleSaveDraft = (val) => {
    let validate = handelValidate(schedule);

    if (Object.keys(validate)?.length) {
      setError(validate);
      return;
    }

    const isEmpty = areValuesEmpty(schedule);
    if (isEmpty) {
      let data = {
        title: val ? val.Title : schedule.title,
        keywords: val ? val.Keywords.join(", ") : schedule.keyword.join(", "),
        scheduled_date: val
          ? val.Scheduled_Time
          : schedule?.date
            ? moment(schedule?.date).format("DD-MMM-YYYY hh:mm")
            : "",
        content: val ? val.Content : schedule.content,
        org_id: val ? val.Org_Id : org_info?.default_organizations?.Id,
        id: schedule.id,
      };

      dispatch(toggleLoader(true));
      axios
        .put(process.env.REACT_APP_POST, data, {
          withCredentials: process.env.REACT_APP_CREDENTIALS,
        })
        .then((res) => {
          dispatch(toggleLoader(false));
          setOpen(false);
          setEdit(false);
          usersDetails();
          setError(null);
          toast.success('Saved');
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false));
        });
    }
  };

  // const handleEdit = (item) => {
  //   let parts = location.pathname.split('/');

  //   let editor = parts.slice(3).join('/');
  //   

  //   // navigate(`/c${org_id}/${editor}`, { state: { item: item, tabvalue: tabvalue } });
  //   const keywordsArray = item?.Keywords?.split(",");
  //   const val = moment(new Date(item?.Scheduled_Time)).format(
  //     "DD-MMM-YYYY hh:mm a"
  //   );
  //   setSchedule({
  //     ...schedule,
  //     title: item.Title,
  //     keyword: keywordsArray,
  //     content: item.Content,
  //     date: val === "Invalid date" ? "" : val,
  //     id: item.Id,
  //   });
  //   // setOpen(true);
  //   // setEdit(true);

  // };



  const handleFileOption = (key) => {
    switch (key) {
      case "File Upload":
        navPath("/upload");
        break;
      // case 'Create New Post':
      //     setOpen(true);
      //     setSchedule(initalState);
      //     break;
      default:
        break;
    }
  };

  const handleApprove = () => {
    let data = {
      org_id: org_info?.default_organizations?.Id,
      post_id: schedule.id,
    };

    dispatch(toggleLoader(true));
    axios
      .post(process.env.REACT_APP_PUBLISH, data, {
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        dispatch(toggleLoader(false));

        setOpen(false);
        setEdit(false);
        usersDetails();
        setError(null);
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
  };

  const handleRejectandDraft = () => {
    let data = {
      org_id: org_info?.default_organizations?.Id,
      post_id: schedule.id,
    };

    dispatch(toggleLoader(true));
    axios
      .post(process.env.REACT_APP_REJECT, data, {
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        dispatch(toggleLoader(false));
        setOpen(false);
        setEdit(false);
        usersDetails();
        setError(null);
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
  };

  const debouncedSendRequest = useMemo(() => debounce(postSearch, 300), []);

  const handleSearch = (value) => {
    setSearchValue(value);
    debouncedSendRequest(value, tabvalue);
  };

  const paperStyle = {
    backgroundColor: 'var(--primary)',
    cursor: "pointer",
    display: !inputValue && 'none',
  };

  const getTooltipTitle = () => {
    return (
      <InputSchedule />
    );
  };

  const handleSchedule = (date) => {

    setSchedule({ ...schedule, date: date })
  }

  // const filterPassedTime = (time) => {
  //   // const currentDate = new Date();
  //   // const selectedDate = new Date(time);

  //   const timeInTimezone = moment.tz(time, "America/New_York");
  //   const hour = timeInTimezone.hour();

  //   return hour;
  // };

  const getMinTimeForTimezone = (timezone) => {
    const now = moment().tz(timezone);
    const hours = now.hours();
    const minutes = now.minutes();
    return new Date().setHours(hours, minutes, 0, 0);
  };


  return (
    <Box className={styles.main_wrap}>
      <Box className={styles.home_head}>
        <Box className={styles.home_page_name}>Posts</Box>
        <Box className={styles.home_page_btn_div}>
          <Box className={styles.upload_body}>
            <Button
              className={styles.upload_button}
              variant="contained"
              onClick={() => {
                // setOpen(true);
                navigate(`/c${org_id}/create-new-post`);
                // navigate(`/c${org_id}/table-detail`);
                setSchedule(initalState);
              }}
            >
              <AddIcon
                fontSize="small"
                sx={{ strokeWidth: 1 }}
                className={styles.file_upload_svg}
              />
              Create New Post
            </Button>
            <Select
              value={""}
              className={styles.upload_document}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none !important",
                },
              }}
              MenuProps={{
                classes: {
                  paper: "welcome_page_select",
                  border: "none",
                  color: "#fff !important",
                },
              }}
              onChange={(e) => handleFileOption(e.target.value)}
            >
              {uploadOptions?.map((s, i) => (
                <MenuItem
                  className={styles.file_upload_wrapper}
                  key={i}
                  value={s}
                >
                  {s === "File Upload" ? (
                    <img
                      src={
                        process.env.REACT_APP_IMAGE_URL +
                        "UploadFileSvg/file-upload.svg"
                      }
                      className={styles.file_upload_svg}
                      alt="fileUpload"
                    />
                  ) : (
                    <PostAddIcon
                      fontSize="small"
                      sx={{ stroke: "var(--border-color)", strokeWidth: 1 }}
                      className={styles.file_upload_svg}
                    />
                  )}
                  <span>{s}</span>
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Box>
      {postCount && <Box sx={{ width: "100%" }}>
        <Tabs
          className={styles.label_name}
          value={tabvalue}
          onChange={handleChange}
          aria-label="wrapped label tabs example"
        >
          {tableHeader?.map((value, i) => (

            <Tab
              key={headerIdx[value]}
              value={headerIdx[value]}
              label={`${value} (${handelPostCount(value)})`} />
          ))}
        </Tabs>
        <SwipeableViews
          // key={post_info?.value?.data}
          index={tabvalue}
          onChangeIndex={handleChangeIndex}
          className={styles.label_name_swipe}
        >
          {tableHeader?.map((value) => (
            
            <CustomTable
              key={headerIdx[value]}
              tabvalue={tabvalue}
              ids={selectedIds}
              draft={handleDraft}
              // edit={handleEdit}
              tableHeader={tableHeader}
              usersDetails={()=>usersDetails()}
              search={handleSearch}
              searchValue={searchValue}
              changeTabValue={(e) =>{ setTabValue(e)}}
            />
          ))}
        </SwipeableViews>
      </Box>}
      <Modal
        open={open}
        sx={{ "& .MuiBackdrop-root": { backgroundColor: "#13214440" } }}
        closeAfterTransition
      >
        <Box className={styles.file_wrap}>
          <IconButton
            className={styles.close_btn}
            onClick={() => {
              setOpen(false);
              setEdit(false);
              setError(null);
              setSchedule({ ...schedule, keyword: [] });
              // setSelectedOptions([])
            }}
          >
            <CloseIcon sx={{ "&:hover": { color: "#ed4c78" } }} />
          </IconButton>
          <Box className={styles.post_file_wrapper}>
            <Box className={styles.file_post_container}>
              <InputLabel className={styles.post_label}>
                Title <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                placeholder="Enter the title"
                disabled={edit && tabvalue === 4 ? true : false}
                className={styles.file_post_input}
                onChange={(e) =>
                  setSchedule({ ...schedule, title: e.target.value })
                }
                sx={{
                  "& .MuiFormHelperText-root": {
                    marginLeft: '0px !important'
                  },
                  input: {

                    "&::placeholder": {    // <----- Add this.
                      opacity: 1,
                      color: '#d9d9e3',
                    },
                  },
                  fieldset: { border: "1px solid #E6E7E9" },
                  "& .MuiInputBase-input.Mui-disabled": {
                    color: "black", // Change this to your desired color
                    "-webkit-text-fill-color": "unset",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: "var(--primary) !important",
                    },
                    "& fieldset.MuiOutlinedInput-notchedOutline": {
                      border:
                        !schedule?.title && error?.title
                          ? "1px solid var(--danger)"
                          : "1px solid #E6E7E9",
                    },
                    fontSize: "14px",
                    paddingLeft: "2px",
                    height: "41.92px",
                  },
                }}
                style={{ color: "black" }}
                value={schedule?.title}
                error={!schedule?.title && error?.title ? true : false}
                helperText={!schedule?.title && error?.title}
              />
            </Box>
            <Box
              className={styles.file_post_container}
              sx={{ marginTop: "20px" }}
            >
              <InputLabel className={styles.post_label}>
                Keyword
                {/* <span style={{ color: "red" }}>*</span> */}
              </InputLabel>
              <Box
                sx={{ width: '75%' }}
              >
                <Autocomplete
                  multiple
                  options={options}
                  value={schedule.keyword}
                  disabled={edit && disabledinputs.includes(tabvalue)}
                  limitTags={2}
                  onChange={handleSelectChange}
                  inputValue={inputValue}
                  PaperComponent={({ children }) => (
                    <Paper style={paperStyle}>{children}</Paper>
                  )}
                  onInputChange={handleInputChange}
                  renderInput={(params) => (
                    <TextField
                      placeholder={!schedule?.keyword[0] && "Enter the keyword"}
                      {...params}
                      onKeyDown={(e) => {
                        if (e.key === "Enter")
                          setSchedule({ ...schedule, keyword: [...schedule.keyword, inputValue] });
                        // setSelectedOptions([...selectedOptions,inputValue])
                      }}
                      variant="outlined"
                      error={!schedule?.keyword[0] && error?.keyword ? true : false}
                      helperText={error?.keyword && !schedule?.keyword[0] && error?.keyword}
                      sx={{
                        '& .MuiFormHelperText-root': {
                          color: '#d32f2f', // Change the color here
                          margin: 0, // Remove margin
                        },
                        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#d32f2f' // Set border color to red when error
                        },
                        input: {
                          "&::placeholder": {    // <----- Add this.
                            opacity: 1,
                            color: '#d9d9e3',
                            fontSize: '14px',
                          },
                        },

                      }} />
                  )}
                  renderOption={(props, option, { inputValue }) => (
                    inputValue && (
                      <Box {...props} className={styles.tag_selection}>
                        <Box className={styles.tag_value}>{option}</Box>
                        <Box className={styles.tag_text} >
                          Add this as new tag
                        </Box>
                      </Box>
                    )
                  )}
                  sx={{
                    '& .MuiInputBase-root': {
                      padding: 0,
                      borderColor: 'var(--border-color)',
                    },
                    '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                      padding: '7.5px 4px 7.5px 15px', // Change padding here
                    },
                    '& .MuiAutocomplete-input': {
                      padding: 0
                    },
                    '& .MuiAutocomplete-popupIndicator .MuiSvgIcon-root': {
                      display: 'none'
                    },
                    '& .MuiOutlinedInput-root': {
                      borderColor: 'var(--border-color)',
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid var(--primary)'
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'var(--primary)'
                      },
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--border-color)'
                    },
                    width: '100%',
                  }}
                />
              </Box>
            </Box>

            {edit && (
              <Box
                className={styles.file_post_container}
                sx={{ marginTop: "20px" }}
              >
                <InputLabel className={styles.post_label}>
                  Content <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Box
                  className={
                    !schedule?.content && error?.content
                      ? styles.tag_input_error
                      : styles.tag_input_wrap
                  }
                >
                  <TextField
                    multiline
                    minRows={9}
                    maxRows={10}
                    className={styles.file_post_inputs}
                    onChange={(e) =>
                      setSchedule({ ...schedule, content: e.target.value })
                    }
                    sx={{
                      fieldset: { border: "1px solid #E6E7E9" },
                      "& .MuiInputBase-input.Mui-disabled": {
                        color: "black", // Change this to your desired color
                        "-webkit-text-fill-color": "unset",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": {
                          borderColor: "var(--primary) !important",
                        },
                        "& fieldset.MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #E6E7E9",
                        },
                        fontSize: "14px",
                        paddingLeft: "10px",
                      },
                    }}
                    disabled={edit && tabvalue === 4 ? true : false}
                    value={schedule?.content}
                  />
                </Box>

              </Box>

            )}
            {error?.content && !schedule?.content && (
              <Box className={styles.helper_text_keyword}>{error?.content}</Box>
            )}
            <Box
              className={styles.file_post_container}
              sx={{ marginTop: "20px" }}
            >
              <InputLabel className={styles.post_label}>
                Schedule Date
                {edit && tabvalue === 1 && (
                  <span style={{ color: "red" }}>*</span>
                )}
              </InputLabel>
              {tabvalue === 1 && (
                <Box
                  className={
                    !schedule?.date && error?.date
                      ? styles.tag_input_errors
                      : styles.tag_input_wraps
                  }
                ></Box>
              )}

              <Box className={styles.date_picker_wrap} sx={{
                "& .MuiTooltip-tooltip": {
                  maxWidth: '400px !important',
                  width: '400px !important',
                },
              }}>

                <DatePicker
                  disabled={edit && disabledinputs.includes(tabvalue)}
                  selected={schedule?.date}
                  className={styles.date_picker}
                  onChange={(date) => handleSchedule(date)}
                  minDate={new Date()}
                  timeInputLabel="Time:"
                  dateFormat="dd-MMM-yyy h:mm aa"
                  showTimeSelect
                  timeCaption="Time"
                  timeIntervals={30}
                  wrapperClassName={styles.date_pickerr}
                  error={true}
                  filterTime={(time) => time >= minTime}
                />


                {/* 
                <ClickAwayListener  onClickAway={handleTooltipClose}>
                  <div style={{ width: "100%" }}>
                    <Tooltip
                    
                      PopperProps={{
                        disablePortal: true
                      }}
                      onClose={handleTooltipClose}
                      open={toolTipopen}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={getTooltipTitle()}
                      placement="top"
                      arrow
                    >
                      <OutlinedInput
                        className={styles.outline_input}
                        sx={{ width: '100%' }}
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        onClick={() => handleTooltipOpen()}
                      />
                    </Tooltip>
                  </div>
                </ClickAwayListener> */}
                <Box className={styles.clear_icon_wrap}>
                  {schedule?.date && (
                    <CloseIcon
                      onClick={() =>
                        edit && tabvalue === 4
                          ? {}
                          : setSchedule({...schedule, date: "" })
                      }
                      fontSize="small"
                      sx={{
                        display: edit && disabledinputs.includes(tabvalue) && 'none',
                        "&:hover": {
                          color: "#ed4c78",
                          cursor:
                            edit && tabvalue === 4 ? "default" : "pointer",
                        },
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Box>
            {edit && tabvalue === 1 && error?.date && !schedule?.date && (
              <Box className={styles.helper_text_keyword}>{error?.date}</Box>
            )}
            <Box className={styles.btn_container}>
              {edit ? (
                tabvalue === 4 ? (
                  <Box className={styles.btn_group}>
                    <Button
                      variant="contained"
                      className={styles.review_btn}
                      onClick={() => {
                        handleRejectandDraft();
                      }}
                    >
                      Reject and Revert to Draft
                    </Button>
                    <Button
                      variant="contained"
                      className={styles.review_btn}
                      onClick={() => {
                        handleApprove();
                      }}
                    >
                      Approve & Publish
                    </Button>

                  </Box>
                ) : tabvalue === 0 ? (
                  <Box className={styles.btn_group}>
                    <Button
                      variant="contained"
                      className={styles.review_btn}
                      onClick={() => {
                        handleSaveDraft();
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      className={styles.review_btn}
                      onClick={() => {
                        handleApprove();
                      }}
                    >
                      Publish
                    </Button>

                  </Box>
                ) : (
                  <Box className={styles.btn_group}>
                    {tabvalue === 1 ? schedule.date ? <Button
                      variant="contained"
                      className={styles.review_btn}
                      onClick={() => {
                        handleSaveDraft();
                      }}
                    >
                      Save
                    </Button> : ''
                      : <Button
                        variant="contained"
                        className={styles.review_btn}
                        onClick={() => {
                          handleSaveDraft();
                        }}
                      >
                        Save
                      </Button>}
                    {tabvalue === 1 ? schedule?.date ? "" : <Button
                      variant="contained"
                      className={styles.review_btn}
                      onClick={() => {
                        handleApprove();
                      }}
                    >
                      Publish
                    </Button> : <Button
                      variant="contained"
                      className={styles.review_btn}
                      onClick={() => {
                        handleApprove();
                      }}
                    >
                      Publish
                    </Button>}
                  </Box>
                )
              ) : (
                <Box className={styles.btn_group}>
                  <Button
                    variant="outlined"
                    className={styles.review_draft_btn}
                    onClick={() => {handlePost(1)}}
                  >
                    Draft
                  </Button>
                  <Button
                    variant="contained"
                    className={styles.review_btn}
                    onClick={() => {handlePost(2)}}
                    disabled={schedule.date ? !enableAccess : false}
                  >
                    Publish
                  </Button>
                  <Button
                    variant="contained"
                    className={styles.review_btn}
                    onClick={() =>{ handlePost(3)}}
                    disabled={schedule.date ? !enableAccess : false}
                  >
                    Send for review
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={confirmation}
        sx={{ "& .MuiBackdrop-root": { backgroundColor: "#13214440" } }}
        closeAfterTransition
      >
        <Box className={styles.file_wrap}>
          <Box>
            <Box className={styles.popup_head}>
              {postType === "Trash"
                ? "Move post to trash?"
                : "Delete this post?"}
            </Box>
            <IconButton
              className={styles.close_btn}
              onClick={() => setConfirmation(false)}
            >
              <CloseIcon sx={{ "&:hover": { color: "#ed4c78" } }} />
            </IconButton>
          </Box>
          <Box className={styles.popup_paragraph}>
            {postType === "Trash"
              ? trashAlert[tabvalue]
              : "Are you sure you want to permanently delete this post?"}
          </Box>
          <Box className={styles.popup_btn}>
            <Button
              color="error"
              variant="outlined"
              onClick={() => setConfirmation(false)}
            >
              Cancel
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={() => postOptions(id, postType)}
            >
              {postType === "Trash" ? `Move to trash` : "Delete"}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Home;
