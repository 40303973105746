import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, Modal, TextField, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { toggleLoader } from "../../Store/loader/loaderReducer";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import JoditEditor from "jodit-react";
import { getPathFromLocalStroage } from "../../utils";

import './Editor.css';
import styles from "./Editor.module.css";

import moment from "moment";
import axios from "axios";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SyncIcon from '@mui/icons-material/Sync';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import ScheduleModal from "../Modal/ScheduleModal/ScheduleModal";
import ConfirmationModal from "../Modal/ConfirmationModal/ConfirmationModal";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Editor = () => {
  const editorRef1 = useRef(null);
  const joditWrapperRef = useRef(null);
  const textAreaRef = useRef(null);
  const { id } = useParams();

  const toolbarContainerRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const scheduleValues = location?.state?.item;
  const org_info = useSelector((state) => state?.org_info?.value);
  const org_id = org_info?.default_organizations?.Id;

  const dispatch = useDispatch();
  const [tabvalue, setTabvalue] = useState(null);
  const [schedule, setSchedule] = useState({ title: "", keyword: [], date: "", content: "", id: "" });
  const [open, setOpen] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [type, setType] = useState('');
  const maxChars = 215;
  const pathLocal = getPathFromLocalStroage();




  useEffect(() => {
    handleDrafts()
  }, [location])

  const handleDrafts = () => {
    let data = {
      post_id: id,
      org_id: org_id,
    };

    dispatch(toggleLoader(true));
    axios
      .get(process.env.REACT_APP_POST, {
        params: data,
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        const response = res.data.data[0]
        setSchedule({ title: response.Title, keyword: response.Keywords, date: response.Scheduled_Time, content: response?.Content, id: response.Id })
        setTabvalue(response.Status)
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
  };


  useEffect(() => {
    const handleResize = () => {
      const titleTextarea = document.querySelector('.jodit-toolbar__box');
      const compStyles = window.getComputedStyle(titleTextarea);
      document.documentElement.style.setProperty('--custom_textarea_margin_top', compStyles.height)

    }
    handleResize();
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (toolbarContainerRef.current) {
      config.toolbarContainer = toolbarContainerRef.current;
    }
  }, []);

  useEffect(() => {
    if (type && type !== 'Trash') {
      postOptions();
    }
  }, [type]);

  function getTextContentHeight(textarea) {
    const originalHeight = textarea.style.height;
    textarea.style.height = 'auto';
    textarea.scrollTop = 10000;
    const contentHeight = textarea.scrollHeight;
    textarea.scrollTop = 0;
    return contentHeight;
  }



  const handleChange = (event) => {


    const inputText = event.target.value;
    const isDeleting = inputText.length < schedule.title.length
    const contentHeight = getTextContentHeight(textAreaRef.current) + (isDeleting ? 0 : 0);
    textAreaRef.current.style.height = contentHeight + 'px'
    document.documentElement.style.setProperty('--toolbar__box_margin_bottom', contentHeight + 'px')


    if (inputText.length <= maxChars) {
      setSchedule({ ...schedule, title: inputText })

    } else {
      // Optionally, show a message to the user
      // alert(`You can only enter up to ${maxChars} words.`);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const config = useMemo(
    () => ({
      // "enter": "BR",

      "readonly": tabvalue === 4 ? true : false,
      "showCharsCounter": false,
      "defaultActionOnPaste": "insert_clear_html",
      "showWordsCounter": false,
      "showXPathInStatusbar": false,
      'disablePlugins': 'add-new-line',
      'autoresize': false,
      "allowResizeY": false,
      uploader: {
        insertImageAsBase64URI: true,
        // url: 'https://test.centilio.com/drive/blog/attachment',
        // format: 'json',
        // method: 'POST',
        // isSuccess: resp => !resp.error,
        // prepareData: function (formData) {
        //   formData.append('file', formData.get('files[0]'));
        //   formData.append("org_id", org_id)
        //   formData.delete("path")
        //   formData.delete("source")
        //   formData.delete("default")
        //   formData.delete("files[0]")
        //   return formData
        // },
        // withCredentials: process.env.REACT_APP_CREDENTIALS,
        // process: function (resp) {
        //   if (resp && resp.url) {
        //     return {
        //       files: [resp.url]
        //     };
        //   } else {
        //     console.error("Error in API response:", resp);
        //     throw new Error("API response does not contain a valid 'file.url'.");
        //   }
        // },
        // defaultHandlerSuccess: function (data) {
        //   if (data.files && data.files.length) {
        //     const imageUrl = data.files[0];
        //     this.selection.insertImage(imageUrl);
        //   } else {
        //     console.error("No valid files found in the response.");
        //   }
        // }
      },
      buttons: ['bold', 'italic', 'underline', 'image', 'strikethrough', 'eraser', 'ul', 'ol', 'font', 'fontsize', 'paragraph', 'lineHeight', 'indent', 'outdent', 'left', 'undo', 'redo']
    }), []);

  const insertBase64Image = () => {
    const base64Image = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...'; // Add your full base64 string here
    // console.log(editorRef1.current.selection, 'editorRef1.current');
    // Ensure the editor is initialized
    if (editorRef1.current && editorRef1.current.editor) {
      editorRef1.current.editor.selection.insertHTML(`<img src="${base64Image}" alt="Base64 Image" />`);
    } else {
      console.error('Editor not initialized');
    }
  };

  const handleSaveDraft = () => {

    let data = {
      title: schedule.title,
      keywords: schedule.keyword,
      scheduled_date: moment(schedule?.date, "DD/MM/YYYY hh:mm A").format("DD-MMM-YYYY hh:mm"),
      content: schedule.content,
      org_id: org_info?.default_organizations?.Id,
      id: schedule.id,
    };
    if (tabvalue === 2) {
      delete data.scheduled_date;
    }
    dispatch(toggleLoader(true));
    axios
      .put(process.env.REACT_APP_POST, data, {
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        dispatch(toggleLoader(false));
        toast.success('Saved');
        // navigate(`/c${org_id}${pathLocal.path}`);
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
  };

  const handleApprove = () => {

    let data = {
      title: schedule.title,
      org_id: org_info?.default_organizations?.Id,
      post_id: schedule.id,
      content: schedule.content,
    };

    dispatch(toggleLoader(true));
    axios
      .post(process.env.REACT_APP_PUBLISH, data, {
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        dispatch(toggleLoader(false));
        localStorage.setItem(
          "path",
          JSON.stringify({ path: "/post/published", value: 2 })
        );
        navigate(`/c${org_id}${pathLocal.path}`);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.details);
        dispatch(toggleLoader(false));
      });
  };

  const postOptions = () => {
    let data = {
      ids: [schedule.id],
      limit: 100,
      cursor: 1,
      org_id: org_info?.default_organizations?.Id,
    };
    dispatch(toggleLoader(true));
    const API =
      type === "Trash"
        ? process.env.REACT_APP_POST
        : type === "Delete"
          ? process.env.REACT_APP_TRASH :
          type === "Revert" ?
            process.env.REACT_APP_REVERTDRAFT :
            process.env.REACT_APP_RESTORE
    if (type === "Restore") {
      axios
        .post(API, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
        .then((res) => {
          dispatch(toggleLoader(false));
          localStorage.setItem(
            "path",
            JSON.stringify({ path: "/post/drafts", value: 0 })
          );
          navigate(`/c${org_id}${pathLocal.path}`);
          toast.success(
            type === "Restore" && "Post restored and moved to drafts."
          );
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false));
          navigate(`/c${org_id}${pathLocal.path}`);
        });
    }
    else if (type === "Revert") {
      axios
        .post(API, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
        .then((res) => {
          dispatch(toggleLoader(false));
          localStorage.setItem(
            "path",
            JSON.stringify({ path: "/post/drafts", value: 0 })
          );
          navigate(`/c${org_id}${pathLocal.path}`);
          // toast.success(
          //   postType === "Restore" && "Post restored and moved to drafts."
          // );
        })

        .catch((err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false));
        });
    }


    else {
      axios
        .delete(API, {
          data: data,
          withCredentials: process.env.REACT_APP_CREDENTIALS,
        })
        .then((res) => {
          dispatch(toggleLoader(false));
          setConfirmation(false)
          navigate(`/c${org_id}${pathLocal.path}`);
          {
            type === "Trash" && localStorage.setItem(
              "path",
              JSON.stringify({ path: "/post/trash", value: 5 })
            );
          }
          toast.success(
            type === "Trash" ? "Post moved to trash." : "Post deleted."
          );
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false));
          navigate(`/c${org_id}${pathLocal.path}`);
          setConfirmation(false)
        });
    }

  };

  const handleDraft = () => {


    let data = {
      title: schedule.title,
      keywords: schedule.keyword,
      scheduled_date: moment(schedule?.date, "DD/MM/YYYY hh:mm A").format("DD-MMM-YYYY hh:mm"),
      content: schedule.content,
      org_id: org_info?.default_organizations?.Id,
    };
    if (data.scheduled_date === 'Invalid date') {
      delete data.scheduled_date;
    }

    dispatch(toggleLoader(true));
    axios
      .post(process.env.REACT_APP_POST, data, {
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        dispatch(toggleLoader(false));
        navigate(`/c${org_id}${pathLocal.path}`);
      })
      .catch((err) => {
        toast.error(err.message);
        navigate(`/c${org_id}${pathLocal.path}`);
        dispatch(toggleLoader(false));
      });
  };

  const getTooltipTitle = (item) => {
    return (
      <Box className={styles.table_moreinfo_popper}>

        {/* <Button
          startIcon={<SyncIcon />}
          variant="text"
          onClick={() => handleSaveDraft()}
        >
          Save as draft
        </Button> */}
        {tabvalue !== 2 && <Button
          startIcon={<ScheduleSendIcon />}
          variant="text"
          onClick={() => setOpen(true)}
        >
          Schedule post
        </Button>}
        {tabvalue === 2 && <Button
          startIcon={<SyncIcon />}
          variant="text"
          onClick={() => { setType("Revert") }}
        >
          Revert to Draft
        </Button>}
        <Button
          startIcon={<ContentCopyIcon />}
          variant="text"
          onClick={() => handleDraft()}
        >
          Duplicate draft
        </Button>
        <Button
          startIcon={<DeleteOutlineIcon />}
          className={styles.table_trash_btns_delete}
          variant="text"
          onClick={() => { setType("Trash"); setConfirmation(true) }}
        >
          Move to trash
        </Button>
        {/* <Button
          startIcon={<DeleteOutlineIcon />}
          className={styles.table_trash_btns_delete}
          variant="text"
          onClick={() => {setType("Delete");setConfirmation(true)}}
        >
          Delete
        </Button> */}
      </Box>
    );
  };


  const handleSchedule = (date) => {

    let data = {
      org_id: org_info?.default_organizations?.Id,
      post_id: schedule.id,
      scheduled_date: date,
    };

    dispatch(toggleLoader(true));
    if (tabvalue === 0) {
      axios.post(process.env.REACT_APP_SCHEDULE, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
        .then((res) => {
          dispatch(toggleLoader(false));
          localStorage.setItem(
            "path",
            JSON.stringify({ path: "/post/scheduled", value: 1 })
          );
          navigate(`/c${org_id}${pathLocal.path}`);
          setOpen(false);
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false));
          setOpen(false)
        });
    } else {
      axios.put(process.env.REACT_APP_SCHEDULE, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
        .then((res) => {
          dispatch(toggleLoader(false));
          localStorage.setItem(
            "path",
            JSON.stringify({ path: "/post/scheduled", value: 1 })
          );
          navigate(`/c${org_id}${pathLocal.path}`);
          setOpen(false);
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false));
          setOpen(false)
        });
    }
  };


  const handleRejectandDraft = () => {
    let data = {
      org_id: org_info?.default_organizations?.Id,
      post_id: schedule.id,
    };

    dispatch(toggleLoader(true));
    axios
      .post(process.env.REACT_APP_REJECT, data, {
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        dispatch(toggleLoader(false));
        toast.success('Successfully Rejected');
        localStorage.setItem(
          "path",
          JSON.stringify({ path: "/post/drafts", value: 0 })
        );
        navigate(`/c${org_id}${pathLocal.path}`);

      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
  };

  const handleCancelSchedule = () => {
    const data = {
      org_id: org_info?.default_organizations?.Id,
      post_id: schedule.id,
    }
    axios
      .delete(process.env.REACT_APP_SCHEDULE, {
        data: data,
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        dispatch(toggleLoader(false));
        toast.success('Success');
        navigate(`/c${org_id}${pathLocal.path}`);

      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
  };

  const handleEditorChange = (newContent) => {
    setSchedule({ ...schedule, content: newContent });
  };

  return (
    <Box className='editor'>
      <Box className={styles.main_div}>
        {tabvalue !== null && tabvalue !== 4 ?
          <Box className={styles.topbar_back_btn} >
            <Box sx={{ paddingTop: '10px' }}>
              <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={() => navigate(`/c${org_id}${pathLocal.path}`)}> Back </Button>
            </Box>
            <Box className={styles.topbar_btn}>
              <Button onClick={handleSaveDraft} variant="outlined" disabled={schedule.title.length <= 0}> Save </Button>
              <Button onClick={handleApprove} variant="contained" disabled={schedule.title.length <= 0}> Publish </Button>

              <Box className={styles.table_user_more_icon}>
                <Tooltip
                  title={getTooltipTitle()}
                  componentsProps={{
                    tooltip: { sx: { bgcolor: "white" } },
                  }}
                  placement="bottom"
                  arrow
                >
                  <MoreHorizIcon
                    className={styles.table_user_more_btn}
                    sx={{
                      border: "1px solid rgba(231, 234, 243, 0.70)",
                      borderRadius: "50%",
                      fill: "#377DFF",
                    }}
                  />
                </Tooltip>
              </Box>
            </Box>
          </Box> : tabvalue !== null && <Box className={styles.topbar_back_btn} >
            <Box sx={{ paddingTop: '10px' }}>
              <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={() => navigate(`/c${org_id}${pathLocal.path}`)}> Back </Button>
            </Box>

            <Box className={styles.topbar_btn}>

              <Button onClick={handleApprove} variant="contained" > Approve & Publish </Button>
              <Button onClick={handleRejectandDraft} variant="outlined" color="error"> Reject </Button>
            </Box>
          </Box>
        }
        <Box
          sx={{
            padding: '0px 60px 0px 60px',
            height: "100%",
            position: 'relative'
          }}
          ref={joditWrapperRef}
        >
          <TextField
            multiline

            sx={{
              backgroundColor: '#fff', position: 'absolute', resize: 'none',
              "& fieldset": { border: 'none' },
              "& .MuiOutlinedInput-input": { fontSize: '24px' },
              "& .Mui-disabled textarea": {
                WebkitTextFillColor: 'black', fontSize: '24px',
                fontWeight: '400'
              },

            }}
            className="custom_textarea"
            onKeyDown={handleKeyDown}
            onChange={(e) => handleChange(e)}
            value={schedule.title}
            ref={textAreaRef}
            disabled={tabvalue === 4 ? true : false}
          />
          {/* <button onClick={insertBase64Image}>Insert Base64 Image</button> */}

          <JoditEditor
            ref={editorRef1}
            value={schedule?.content}
            config={config}
            onBlur={handleEditorChange}
          />

        </Box>
        <ScheduleModal open={open} Schedule={(date) => handleSchedule(date)} setOpen={setOpen} scheduleTime={schedule.date} cancelSchedule={() => handleCancelSchedule()} tabvalue={tabvalue} />
        <ConfirmationModal confirmation={confirmation} setConfirmation={setConfirmation} id={schedule.id} type={type} postOptions={() => postOptions()} />
      </Box>

    </Box>

  )
}
export default Editor;