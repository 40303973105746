import React, { useState } from 'react'
import styles from "./ScheduleModal.module.css";
import { Box, Button, Modal } from '@mui/material';
import { LocalizationProvider, StaticDatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import dayjs from 'dayjs';
import moment from 'moment';
import { useSelector } from 'react-redux';

const ScheduleModal = ({ open, Schedule, setOpen, scheduleTime, cancelSchedule, tabvalue }) => {


    const [date, setDate] = useState('');
    const org_info = useSelector((state) => state?.org_info?.value);
    const timeZone = JSON.parse(org_info.setting).timezone;
    

    const currentDate = moment.tz(timeZone).format('YYYY-MM-DD hh:mm a');
    const currentDates = moment(currentDate, 'YYYY-MM-DD hh:mm a')._d
    const selectedDates =date.$d
    const enableAccess = currentDates <= selectedDates


    const parseDateString = (dateString) => {
        const [datePart, timePart, period] = dateString?.split(' ');
        const [day, month, year] = datePart?.split('/')?.map(Number);
        let [hours, minutes] = timePart?.split(':')?.map(Number);

        if (period === 'PM' && hours !== 12) {
            hours += 12;
        } else if (period === 'AM' && hours === 12) {
            hours = 0;
        }

        return new Date(year, month - 1, day, hours, minutes);
    };
    const parsedDate = scheduleTime ? parseDateString(scheduleTime) : null;



    const combineDateTime = (dateStr, timeStr) => {
        const [day, month, year] = dateStr.split(/[- ]/);
        const [_, time, period] = timeStr.split(/[ /]/);
        return `${day}-${month}-${year} ${time} ${period}`;
    };


    const handleDateandTime = (e, val) => {
        setDate(e)
     
    }

    const handileSchedule = () => {

        const formattedDate = moment(date.$d).format('DD-MMM-YYYY hh:mm a');
        const now = moment();
        const nowdate = now.format('DD-MMM-YYYY hh:mm a')
        const schedule = moment(scheduleTime, "DD/MM/YYYY hh:mm A").format("DD-MMM-YYYY hh:mm a");

        Schedule(date ? formattedDate : scheduleTime ? schedule : nowdate);
    }

    return (
        <Box>
            <Modal
                open={open}
                sx={{ "& .MuiBackdrop-root": { backgroundColor: "#13214440" } }}
                closeAfterTransition
            >
                <Box className={styles.file_wrap}>
                    <Box className={styles.file_head_name} >Schedule Post</Box>
                    <Box className={styles.schedule_date_time}>
                        <Box>When do you want to publish your post?</Box>
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <Box>
                                <LocalizationProvider dateAdapter={AdapterDayjs}
                                >
                                    <StaticDatePicker
                                        disablePast
                                        sx={{
                                            "& .MuiPickersLayout-toolbar": {
                                                display: "none !important",
                                            },
                                            "& .MuiPickersLayout-actionBar": {
                                                display: "none !important",
                                            },
                                        }}
                                        // value={scheduleTime ? dayjs(parsedDate) : dateTimes.date ? dayjs(dateTimes.date) : dayjs(new Date())}
                                        value={date ? dayjs(date) : scheduleTime ? dayjs(parsedDate) : dayjs(new Date())}
                                        onChange={e => handleDateandTime(e, 'date')} />
                                </LocalizationProvider>
                            </Box>
                            <Box sx={{ border: '1px solid #e1e1e1', margin: '37px 0px' }} />
                            <Box sx={{ paddingTop: '20px', paddingLeft: '10px' }}>
                                <Box>Time</Box>
                                <LocalizationProvider dateAdapter={AdapterDayjs}  >
                                    <DemoContainer components={['TimePicker', 'TimePicker']}>
                                        <TimePicker

                                            // value={scheduleTime ? dayjs(parsedDate) : dateTimes.time ? dayjs(dateTimes.time) : dayjs(new Date())}
                                            value={date ? dayjs(date) : scheduleTime ? dayjs(parsedDate) : dayjs(new Date())}
                                            onChange={e => handleDateandTime(e, 'time')}
                                            sx={{
                                                "& .MuiInputAdornment-root": {
                                                    display: "none !important",
                                                },
                                                paddingTop: '20px'
                                            }}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: tabvalue === 1 ? 'space-between' : 'end' }}>
                            {tabvalue === 1 && <Box>
                                <Button variant="outlined" startIcon={<EventBusyIcon />} onClick={() => cancelSchedule()}>Cancel schedulling</Button>
                            </Box>}
                            <Box sx={{ display: 'flex', justifyContent: 'end', gap: '20px' }}>
                                <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
                                <Button variant="contained" onClick={() => handileSchedule()}
                                // disabled={dateTimes.date ? dateTimes.time ? false : true : true}
                                disabled={!enableAccess}
                                >Schedule</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
}

export default ScheduleModal